import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { profileDetails } from "../../../api/AdminRequest";
import profileImage from "../../../assets/img/profileImage.png";

const Profile = () => {
  const [profile, setProfile] = useState("");
  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await profileDetails();
        if (data?.success) {
          console.log("data", data);
          setProfile(data?.profile);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getProfile();
  }, []);
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row">
          <div className="col">
            <h3 className="page-title">Profile</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/superAdmin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Profile</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}
      <div className="row">
        <div className="col-md-12">
          <div className="profile-header">
            <div className="row align-items-center">
              <div className="col-auto profile-image">
                <Link to="#">
                  <img
                    className="rounded-circle"
                    alt="User Image"
                    src={profileImage}
                  />
                </Link>
              </div>
              <div className="col ms-md-n2 profile-user-info">
                <div className="row">
                  <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                    Name :
                  </p>
                  <p className="col-sm-9">{profile?.name}</p>
                </div>

                <div className="row">
                  <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                    Email ID :
                  </p>
                  <p className="col-sm-9">{profile?.email}</p>
                </div>
                <div className="row">
                  <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                    Mobile :
                  </p>
                  <p className="col-sm-9">{profile?.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
