import { Button, Form, Input, Select, Spin, Upload } from "antd";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { addService, editService } from "../../../api/ServiceRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImgCrop from "antd-img-crop";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import HandleImageUpload from "../../../helper/ImageCompress";
import GuestPreview from "../../guestView/GuestPreview";
import { getServiceType } from "../../../api/AdminRequest";
const AddService = (editdata) => {
  const localBucketUrl= process.env.LOCAL_IMAGE_BUCKET_URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [serviceType, setServiceType] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [fileList4, setFileList4] = useState([]);
  const ename = editdata?.data?.name ? editdata?.data?.name : "";
  const edomain = editdata?.data?.domain ? editdata?.data?.domain : "";
  const [name, setName] = useState(ename);
  const [domain, setDomain] = useState(edomain);
  useEffect(() => {
    const serviceTypeCall = async () => {
      try {
        const { data } = await getServiceType();
        setServiceType(data.serviceType);
      } catch (error) {
        console.log(error.message);
        if (error.response.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    serviceTypeCall();
  }, []);
  useEffect(() => {

    setFileList1(
      editdata?.data?.backgroundImage1
        ? [
            {
              uid: "-1",
              name: "image.png",
              status: editdata?.data?.backgroundImage1 ? "done" : "",
              url: localBucketUrl+editdata?.data?.backgroundImage1,
            },
          ]
        : []
    );
    setFileList2(
      editdata?.data?.backgroundImage2
        ? [
            {
              uid: "-1",
              name: "image.png",
              status: editdata?.data?.backgroundImage2 ? "done" : "",
              url: localBucketUrl+editdata?.data?.backgroundImage2,
            },
          ]
        : []
    );
    setFileList3(
      editdata?.data?.backgroundImage3
        ? [
            {
              uid: "-1",
              name: "image.png",
              status: editdata?.data?.backgroundImage3 ? "done" : "",
              url: localBucketUrl+editdata?.data?.backgroundImage3,
            },
          ]
        : []
    );
    setFileList4(
      editdata?.data?.serviceLogo
        ? [
            {
              uid: "-1",
              name: "image.png",
              status: editdata?.data?.serviceLogo ? "done" : "",
              url: localBucketUrl+editdata?.data?.serviceLogo,
            },
          ]
        : []
    );
  }, [editdata]);
  const onChange1 = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status) {
      newFileList[0].status = "done";
    }
    setFileList1(newFileList);
  };

  const onChange2 = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status) {
      newFileList[0].status = "done";
    }
    setFileList2(newFileList);
  };
  const onChange3 = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status) {
      newFileList[0].status = "done";
    }
    setFileList3(newFileList);
  };
  const onChange4 = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status) {
      newFileList[0].status = "done";
    }
    setFileList4(newFileList);
  };

  const handleSubmit = async (serviceData) => {
    const BG1ImgCompress = await HandleImageUpload(fileList1);
    const BG2ImgCompress = await HandleImageUpload(fileList2);
    const BG3ImgCompress = await HandleImageUpload(fileList3);
    const LOGOImgCompress = await HandleImageUpload(fileList4);

    const datas = new FormData();

    if (fileList1[0]?.originFileObj && fileList1[0]?.status !== "removed") {
      datas.append("backgroundImage1", BG1ImgCompress);
    }
    if (!fileList1[0]) {
      datas.append("backgroundImage1", "delete");
    }

    if (fileList2[0]?.originFileObj && fileList2[0]?.status !== "removed") {
      datas.append("backgroundImage2", BG2ImgCompress);
    }
    if (!fileList2[0]) {
      datas.append("backgroundImage2", "delete");
    }

    if (fileList3[0]?.originFileObj && fileList3[0]?.status !== "removed") {
      datas.append("backgroundImage3", BG3ImgCompress);
    }
    if (!fileList3[0]) {
      datas.append("backgroundImage3", "delete");
    }

    if (fileList4[0]?.originFileObj && fileList4[0]?.status !== "removed") {
      datas.append("serviceLogo", LOGOImgCompress);
    }
    if (!fileList4[0]) {
      datas.append("serviceLogo", "delete");
    }
    for (const keys in serviceData) {
      datas.append(`${keys}`, `${serviceData[keys]}`);
    }
    try {
      setLoading(true);
      const { data } = editdata?.data
        ? await editService(editdata?.data._id, datas)
        : await addService(datas);
      if (data?.success) {
        setLoading(false);
        toast.success("Service Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/admin/serviceList");
        }, 2500);
      } else if (data?.update) {
        setLoading(false);
        toast.success("Service Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/admin/serviceList");
        }, 2500);
      }
    } catch (error) {
      console.log("errororor");
      setLoading(false);
      toast(error.message);
      if (error.response?.data?.message) {
        toast(error.response.data.message);
      }
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <Spin spinning={loading}>
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col m-4">
              <h3 className="page-title">
                {editdata?.data ? "Edit Service" : "Add Service"}
              </h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  {editdata ? "Edit Service" : "Add Service"}
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          <div className="col-sm-12">
            <div className="servicecard">
              <div className="card-body col-sm-8">
                <Form
                  className="relative"
                  onFinish={handleSubmit}
                  initialValues={{
                    name: editdata?.data?.name,
                    domain: editdata?.data?.domain,
                    type: editdata?.data?.type,
                    redirectUrl: editdata?.data?.redirectUrl,
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <label>
                        Name <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid name!",
                          },
                          {
                            required: true,
                            message: "Please Enter admin Name!",
                          },
                        ]}
                      >
                        <Input
                          className="h-10"
                          placeholder="Admin Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6 mb-[0px]">
                      <label>
                        Domain <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="domain"
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid domain!",
                          },
                          {
                            required: true,
                            message: "Please Enter your domain!",
                          },
                        ]}
                      >
                        <Input
                          className="h-10 "
                          placeholder="Domain Email"
                          onChange={(e) => setDomain(e.target.value)}
                        />
                      </Form.Item>
                      <div>

                      </div>
                       <p className="text-primary mb-[10px]">https://www.oncloudwifi.co/{domain}</p>
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Redirect URL <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="redirectUrl"
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid url!",
                          },
                          {
                            required: true,
                            message: "Please Enter redirect url!",
                          },
                        ]}
                      >
                        <Input className="h-10" placeholder="Redirect URL" />
                      </Form.Item>
                     
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Service Type <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please Add Service Type!",
                  },
                ]}
              >
                {/* <Select
                  style={{  height: "40px" }}
                  placeholder="Service Type"
                  value={editdata?.data?.type?editdata?.data?.type:"restaurant"}
                  options={[
                    {
                      value: "restaurant",
                      label: "Restaurant",
                    },
                    {
                      value: "spa",
                      label: "Spa",
                    },
                    {
                      value: "hotel",
                      label: "Hotel",
                    },
                    {
                      value: "bar",
                      label: "Bar",
                    },
                    {
                      value: "other",
                      label: "Other",
                    },
                  ]}
                /> */}
                <Select
      placeholder="Select an option"
      style={{  height: "40px" }}
      value={editdata?.data?.type?editdata?.data?.type:"Others"}
    >
      {serviceType.map((option, index) => (
        <Option key={index} value={option._id}>
          {option.name}
        </Option>
      ))}
    </Select>
              </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Service Logo <span className="login-danger">*</span>
                      </label>
                      <ImgCrop aspect={1 / 1} aspectSlider showReset>
                        <Upload
                          action=""
                          listType="picture-card"
                          fileList={fileList4}
                          onChange={onChange4}
                          onPreview={onPreview}
                        >
                          {fileList4[0] ? (
                            ""
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </ImgCrop>
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Service Cover Photos{" "}
                        <span className="login-danger">*</span>
                      </label>
                      <div className="flex">
                      <ImgCrop aspect={16 / 9} aspectSlider showReset>
                        <Upload
                          action=""
                          listType="picture-card"
                          fileList={fileList1}
                          onChange={onChange1}
                          onPreview={onPreview}
                          style={{ width: "200px" }}
                        >
                          {fileList1[0] ? (
                            ""
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </ImgCrop>

                      <ImgCrop aspect={16 / 9} aspectSlider showReset>
                        <Upload
                          action=""
                          listType="picture-card"
                          fileList={fileList2}
                          onChange={onChange2}
                          onPreview={onPreview}
                        >
                          {fileList2[0] ? (
                            ""
                          ) : fileList1[0] ? (
                            uploadButton
                          ) : (
                            ""
                          )}
                        </Upload>
                      </ImgCrop>

                      <ImgCrop aspect={9 / 16} aspectSlider showReset>
                        <Upload
                          action=""
                          listType="picture-card"
                          fileList={fileList3}
                          onChange={onChange3}
                          onPreview={onPreview}
                        >
                          {fileList3[0] ? (
                            ""
                          ) : fileList1[0] && fileList2[0] ? (
                            uploadButton
                          ) : (
                            ""
                          )}
                        </Upload>
                      </ImgCrop>
                      </div>
                      
                    </div>

                    
                    <div className="col-12 col-sm-6">

                    <Button
                        className="w-80 h-10 rounded-md text-white bg-sky-700"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="col-sm-4 mr-[40px]">
                <div className="flex ">
                  <div className="mobilePreview">
                    <div className="mobilePreviewB">
                      <div className="themepreviewWrap cursor-none">
                        <GuestPreview
                          name={name}
                          logo={fileList4}
                          background1={fileList1}
                          background2={fileList2}
                          background3={fileList3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Spin>
  );
};

export default AddService;
