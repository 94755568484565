import React, { useEffect, useState } from "react";
import { Button, Carousel, Form, Input, Modal } from "antd";
// import Styles from "./guestlogin-module.scss";
import DefaultBackground from "../../assets/img/mainBg.webp";
import logo from "../../assets/img/file_logo.png";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";
import { guestLogin } from "../../api/GuestRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Checkbox } from "antd";
function GuestLogin({ service, macAddress,redirectUrl }) {
  const localBucketUrl= process.env.LOCAL_IMAGE_BUCKET_URL
  const [open, setOpen] = useState(false);
  const handleSubmit = async (formValues) => {
    const guestData = {
      name: formValues?.name,
      email: formValues?.email,
      phone: formValues?.phone,
      serviceID: service?._id,
      redirectUrl: redirectUrl,
      macAddress: macAddress,
    };
    try {
      const { data } = await guestLogin(guestData);

      if (data?.success) {
        toast.success("Data Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.href = decodeURIComponent(data.redirectUrl)
          
        }, 2500);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <body>
        <div className="relative">
          {/* <img className={guestView-bg} src={service?.backgroundImage1 ? service.backgroundImage1 :mainBg}></img> */}
          <Carousel autoplay dots={false}>
            {service?.backgroundImage1 ? (
              <div>
                <img
                  className="w-full"
                  src={localBucketUrl+service?.backgroundImage1}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {service?.backgroundImage2 ? (
              <div>
                <img
                  className="w-full"
                  src={localBucketUrl+service?.backgroundImage2}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {service?.backgroundImage3 ? (
              <div>
                <img
                  className="w-full"
                  src={localBucketUrl+service?.backgroundImage3}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {!service?.backgroundImage1 &&
            !service?.backgroundImage2 &&
            !service?.backgroundImage3 ? (
              <div>
                <img src={DefaultBackground} alt="" />
              </div>
            ) : (
              ""
            )}
          </Carousel>
          <div className="w-full flex justify-center">
            <div className="absolute top-20  ">
              <div className="guestView-loginForm">
                <h1>
                  <img
                    className="rounded-full"
                    src={service?.serviceLogo ? localBucketUrl+service.serviceLogo : logo}
                  ></img>
                </h1>

                <div>
                  <div className="guestView-card_body">
                    <Form onFinish={handleSubmit}>
                      <div className="">
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              type: "text",
                              message: "The input is not valid number!",
                            },
                            {
                              required: true,
                              message: "Please Enter  Your phone Number!",
                            },
                          ]}
                        >
                          <PhoneInput
                            inputStyle={{
                              background: "rgb(202, 202, 202,0)",
                              border: "0px solid white",
                              borderRadius: "0",
                              borderBottom: "1px solid white",
                              opacity: "1",
                              width: "280px",
                              marginLeft: "10px",
                              marginBottom: "15px",
                              color: "white",
                            }}
                            country={"ae"}
                            enableSearch={true}
                            // value={phone}
                            // onChange={(phone) => setPhone(phone)}
                          />
                        </Form.Item>
                      </div>

                      <div className="">
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              type: "text",
                              message: "The input is not valid name!",
                            },
                            {
                              required: true,
                              message: "Please Enter  Your Name!",
                            },
                          ]}
                        >
                          <Input
                            className="guestView-form_control"
                            placeholder="Name"
                          />
                        </Form.Item>
                      </div>
                      <div className="">
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please Enter your E-mail!",
                            },
                          ]}
                        >
                          <Input
                            className="guestView-form_control"
                            placeholder="Official Email"
                          />
                        </Form.Item>
                      </div>
                      <div className="guestView-Privacy">
                        <Form.Item
                          name="agreement"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error("Should accept agreement")
                                    ),
                            },
                          ]}
                        >
                          <Checkbox className="text-white ml-3">
                            I have read the{" "}
                            <Link
                              onClick={() => {
                                setOpen(true);
                              }}
                              style={{ color: "#007FFF" }}
                            >
                              term & conditions
                            </Link>
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="form-group mb-0">
                        <Button
                          className="guestView-btn_primary"
                          htmlType="submit"
                          type="submit"
                        >
                          CONNECT TO INTERNET
                        </Button>
                      </div>
                      <div className="guestView-forgot">
                        <div className="guestView-text_white">
                          Powered by{" "}
                          {service?.name ? service?.name : "OnCloudWifi"}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Terms and Conditions"
          centered
          open={open}
          footer={false}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          <p>
            At ZEEQR, we are committed to protecting the privacy and security of
            your personal information. This Privacy Policy outlines the types of
            information we collect through our NFC QR-enabled smart business
            card signup process, how we use and protect that information, and
            your rights and choices regarding your personal data. Information
            Collection and Usage 1.1 Personal Information During the NFC
            QR-enabled smart business card signup process, we may collect the
            following personal information from you: Full name Email address
            Phone number Company name Job title Profile picture (optional)
            Social media profiles (optional) 1.2 Usage of Personal Information
            We use the collected personal information for the following
            purposes: To create and manage your account To provide you with
            access to our services and features To communicate with you
            regarding our products, services, and updates To personalize your
            experience and tailor our services to your preferences To improve
            our services, analyze usage patterns, and conduct research To
            respond to your inquiries and provide customer support To protect
            the security and integrity of our systems Data Sharing and
            Disclosure 2.1 Third-Party Service Providers We may engage trusted
            third-party service providers to assist us in delivering our
            services. These service providers have access to your personal
            information solely for the purpose of performing specific tasks on
            our behalf and are obligated not to disclose or use it for any other
            purpose. 2.2 Legal Compliance and Protection We may disclose your
            personal information if required by law, regulation, or legal
            process, or if we believe it is necessary to protect our rights,
            property, or the safety of our users or others. Data Security We
            take reasonable precautions to protect your personal information
            from unauthorized access, use, or disclosure. We use
            industry-standard security measures, including encryption and access
            controls, to safeguard your data. However, please be aware that no
            method of transmission over the Internet or electronic storage is
            100% secure, and we cannot guarantee absolute security. Data
            Retention We retain your personal information for as long as
            necessary to fulfill the purposes outlined in this Privacy Policy
            unless a longer retention period is required or permitted by law.
            When your personal information is no longer needed, we will securely
            dispose of it in accordance with applicable laws and regulations.
            Your Rights and Choices 5.1 Access and Updates You have the right to
            access, update, and correct inaccuracies in your personal
            information. You can do this by accessing your account settings or
            by contacting us using the information provided at the end of this
            Privacy Policy. 5.2 Marketing Communications You can choose to
            opt-out of receiving promotional emails or marketing communications
            from us by following the unsubscribe instructions provided in those
            communications or by contacting us. However, please note that even
            if you opt out, we may still send you non-promotional messages, such
            as those related to your account or our ongoing business
            relationship. 5.3 Cookies and Tracking Technologies We use cookies
            and similar tracking technologies to collect information about your
            usage of our services. You can manage your cookie preferences by
            adjusting your browser settings or by using the cookie consent
            management tools provided on our website. Children's Privacy Our
            services are not intended for individuals under the age of 16. We do
            not knowingly collect personal information from children. If we
            become aware that we have inadvertently collected personal
            information from a child under 16, we will take steps to delete it
            as soon as possible. If you believe that we may have collected
            personal information from a child under 16, please contact us.
            Changes to this Privacy Policy We may update this Privacy Policy
            from time to time. The most.
          </p>
        </Modal>
        <ToastContainer />
      </body>
    </div>
  );
}

export default GuestLogin;
