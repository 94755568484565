import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Modal, Table, Tooltip, Typography } from "antd";
import { onShowSizeChange, itemRender } from "../../Pagination";
import { AiOutlineDelete } from "react-icons/ai";
import { CgBlock, CgUnblock } from "react-icons/cg";
import { LiaUserEditSolid } from "react-icons/lia";
import {
  blockAdmin,
  deleteAdmin,
  getViewAdmins,
} from "../../../api/AdminRequest";
import ErrorLogout from "../../../helper/ErrorLogout";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const { Text } = Typography;
const moment = require("moment");
const AdminList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loader, setLoader] = useState(true);
  const [trigger, setTrigger] = useState();
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const getAdmins = async () => {
      try {
        const { data } = await getViewAdmins();
        setAdmins(data.admins);
        setLoader(false);
      } catch (error) {
        console.log(error.message);
        if (error.response.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    getAdmins();
  }, [trigger]);


  const handleEdit = (editData) => {
    console.log("edit call",editData);
    navigate("/superAdmin/editAdmin", { state: { editData } });
  };

  const handleBlock = async (id) => {
    confirm({
      title: `${
        id?.status === "deactive"
          ? "Do you Want to Unblock the Admin "
          : "Do you Want to Block the Admin"
      }`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const block = async () => {
          try {
            const { data } = await blockAdmin(id._id);
            if (data.update) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        block();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'blue',
          borderColor: 'blue',
          color: 'white', // Text color
        },
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id) => {
    confirm({
      title: "Do you Want to Delete the Admin",
      icon: <ExclamationCircleFilled />,
      content:
        "if you delete the Admin it will effect all the Service under this Admin.",
      onOk() {
        const block = async () => {
          try {
            const { data } = await deleteAdmin(id._id);
            if (data.delete) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        block();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          borderColor: 'red',
          color: 'white', // Text color
        },},
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const column = [
    {
      title: "Sl.No",
      dataIndex: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
     
    },
    {
      title: "Phone",
      dataIndex: "phone",
     
    },
    {
      title: "Service Limit",
      dataIndex: "serviceLimit",
      sorter: (a, b) => a.serviceLimit - b.serviceLimit,
    },
    {
      title: "Expire date",
      dataIndex: "expireDate",
      key: "expire",
      render: (expireDate) => {
        let expiration = moment(expireDate).format("YYYY-MM-DD");
        let current_date = moment().format("YYYY-MM-DD");
        let days = moment(expiration).diff(current_date, "days");
        return (
          <Text
            type={`${days > 10 ? "success" : days > 5 ? "warning" : "danger"}`}
          >
            {moment(expireDate).format("DD-MM-YYYY")}
          </Text>
        );
      },
      // sorter: (a, b) => new Date(a.expireDate) - new Date(b.expireDate),
      
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      dataIndex: "status._id",
      render: (status, _id) => (
        <>
          <div className="actions">
          <Tooltip  title="Edit admin">
              <LiaUserEditSolid className="cursor-pointer"  color="blue" size={20} onClick={() => handleEdit(_id)} />
              </Tooltip>
           
              {_id.status == "active" ? (
                <Tooltip title="block admin">
                  {" "}
                  <CgBlock className="cursor-pointer" color="green" size={20} onClick={() => handleBlock(_id)} />{" "}
                </Tooltip>
              ) : (
                <Tooltip title="unblock admin">
                  <CgUnblock className="cursor-pointer" color="#faad14" size={20} onClick={() => handleBlock(_id)} />
                </Tooltip>
              )}
           
         
              <Tooltip title="delete admin">
                <AiOutlineDelete className="cursor-pointer" color="red" size={20} onClick={() => handleDelete(_id)} />
              </Tooltip>
           
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Admin List</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/superAdmin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Admin List</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <Table
                class="table table-stripped table-hover datatable"
                pagination={{
                  total: admins.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                columns={column}
                loading={loader}
                dataSource={admins}
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminList;
