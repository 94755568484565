import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, Input } from "antd";
// import { forgotPassword } from '../../api/AdminRequest';
import Countdown from "react-countdown";
import { forgotPassword } from "../../api/AdminRequest";

function ForgotPassword({ setOTPView }) {
  const [otpEmail, setOtpEmail] = useState("");
  const [loginError, setLoginError] = useState("");
  const [resend, setResend] = useState(false);
  const [resendBtn, setResendBtn] = useState(false);

  const handleSubmit = async (datas) => {
    setLoginError("");

    try {
      setResend(true);
      setResendBtn(false);
      setTimeout(() => {
        console.log("Send otp in 1 second...");
        setResendBtn(true);
      }, "60000");
      const { data } = await forgotPassword(datas);
      if (data.success) {
        toast.success(`Link Send to your Gmail ID ${otpEmail}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setLoginError(error.response.data.message);
      toast.warn(error.response.data.message);
      setResendBtn(false);
      setResend(false);
    }
  };

  return (

      <div className="">
        <h1 className="mb-4">Forget Password</h1>
        <p1 className="text-black">
          We get it, stuff happens. Just enter your email address below and
          we'll send you a link to reset your password!
        </p1>

        <Form className="relative mt-8" onFinish={handleSubmit}>
        <label>
                        Email <span className="login-danger">*</span>
                      </label>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid email!",
              },
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input className="h-10" placeholder="Enter Your Email" />
          </Form.Item>

          <p className="text-red-500">{loginError}</p>

          <div className="flex gap-3 mb-5">
            <Button
              className="w-full h-auto mt-5 bg-white text-black font-semibold font-['Poppins'] text-lg"
              onClick={() => {
                setOTPView(false);
              }}
             
            >
              Go Back
            </Button>
            {resend ? (
              resendBtn ? (
                <Button
                  className="w-full h-auto mt-5 bg-sky-700 text-white font-semibold font-['Poppins'] text-lg"
                  htmlType="submit"
                >
                  Resend Link
                </Button>
              ) : (
                <Button className="w-full cursor-not-allowed h-auto mt-5 bg-white text-black font-semibold font-['Poppins'] text-lg">
                  <Countdown className="ml-3" date={Date.now() + 60000} />
                </Button>
              )
            ) : (
              <Button
              type="warning"
                className="w-full h-auto mt-5 bg-black text-white font-semibold font-['Poppins'] text-lg hover:border-green-500"
                htmlType="submit"
               
              >
                Send
              </Button>
            )}
          </div>
        </Form>
        <ToastContainer />
      </div>
      
    
  );
}

export default ForgotPassword;
