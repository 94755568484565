import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { headericon04 } from "../imagepath";
import { GiHamburgerMenu } from "react-icons/gi";
import { Modal } from "antd";
import logo from "../../assets/img/oncloudwifi-logo.svg";
import logosmall from "../../assets/img/logoSm.png";
import profileImage from "../../assets/img/profileImage.png";
import { userLogout } from "../../api/AdminRequest";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const Header = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [userName, setUserName] = useState("User");
  const handleLogout = async () => {
    confirm({
      title: "Are you sure to Logout.",
      icon: <ExclamationCircleFilled />,
      onOk() {
        const block = async () => {
          try {
            const refToken = await localStorage.getItem("refToken");

            const { data } = await userLogout(refToken);
            if (data.success) {
              localStorage.clear();
              navigate("/login");
            }
          } catch (error) {
            console.log(error.response.data);

            localStorage.clear();
            navigate("/login");
          }
        };

        block();
      },
      okButtonProps: {
        style: {
          backgroundColor: "red",
          borderColor: "red",
          color: "white", // Text color
        },
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserName(user);
  }, []);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
  };

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    maximizeBtn.addEventListener("click", handleClick);

    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
    };
  }, []);
  const iconColor = "#ffffff";
  const iconSize = "40";
  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          <Link to="/manager/dashboard" className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <Link to="/manager/dashboard" className="logo logo-small">
            <img src={logosmall} alt="Logo" width={30} height={30} />
          </Link>
        </div>
        {/* /Logo */}
        <div className="menu-toggle">
          <Link to="#" id="toggle_btn" onClick={handlesidebar}>
            {/* <i className="fas fa-bars" /> */}
            <GiHamburgerMenu color={iconColor} size={iconSize} />
          </Link>
        </div>

        <Link
          to="#"
          className="mobile_btn"
          id="mobile_btn"
          onClick={() => handlesidebarmobilemenu()}
        >
          <GiHamburgerMenu
            color="black"
            size={30}
            style={{ marginTop: "16px" }}
          />
        </Link>
        {/* /Mobile Menu Toggle */}
        {/* Header Right Menu */}
        <ul className="nav user-menu">
          <li className="nav-item  has-arrow dropdown-heads h-16 flex pt-[20px]">
            <Link to="#" className="win-maximize maximize-icon ">
              <img src={headericon04} alt="" />
            </Link>
          </li>
          {/* User Menu */}
          <li className="nav-item dropdown has-arrow new-user-menus">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                <img
                  className="rounded-circle"
                  src={profileImage}
                  width={31}
                  alt="Ryan Taylor"
                />
                <div className="user-text">
                  <h6>{userName}</h6>
                  {/* <p className="text-muted mb-0">Administrator</p> */}
                </div>
              </span>
            </Link>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  <img
                    src={profileImage}
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="user-text">
                  <h6>{userName}</h6>
                  {/* <p className="text-muted mb-0">Administrator</p> */}
                </div>
              </div>
              <Link className="dropdown-item" to="/manager/profile">
                My Profile
              </Link>
              {/* <Link className="dropdown-item" to="/inbox">
                Inbox
              </Link> */}
              <Link className="dropdown-item" onClick={handleLogout}>
                Logout
              </Link>
            </div>
          </li>
          {/* /User Menu */}
        </ul>
        {/* /Header Right Menu */}
      </div>
      {/* /Header */}
    </>
  );
};

export default Header;
