import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal, Table, Tooltip, Typography } from "antd";
import { onShowSizeChange, itemRender } from "../../Pagination";
import { FaTrashRestoreAlt } from "react-icons/fa";
import ErrorLogout from "../../../helper/ErrorLogout";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { RestoreService, getTrashService } from "../../../api/ServiceRequest";
const { confirm } = Modal;
const { Text } = Typography;
const ServiceTrashList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [service, setService] = useState([]);
  const [loader, setLoader] = useState(true);
  const [trigger, setTrigger] = useState();
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const viewTrashService = async () => {
      try {
        const { data } = await getTrashService();
        setService(data.service);
        setLoader(false);
      } catch (error) {
        console.log(error.message);
        if (error.response.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    viewTrashService();
  }, [trigger]);

  const handleRestore = async (id) => {
    confirm({
      title: "Do you Want to Restore this Service",
      icon: <ExclamationCircleFilled />,
      content:
        "If you Restore this Service user under this service can access this service.",
      onOk() {
        const restoreCall = async () => {
          try {
            const { data } = await RestoreService(id._id);
            if (data.restore) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        restoreCall();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'blue',
          borderColor: 'blue',
          color: 'white', // Text color
        },
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const column = [
    {
      title: "Sl.No",
      dataIndex: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      render: (domain) => {
        return (
          <Link to={`/${domain}`} target="_blank">
            {domain}
          </Link>
        );
      },
    },
    {
      title: "Redirect URL",
      dataIndex: "redirectUrl",
      render: (redirectUrl) => {
        return (
          <Link to={redirectUrl} target="_blank">
            {redirectUrl}
          </Link>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "status._id",
      render: (status, _id) => (
        <>
          <div className="actions">
            <Tooltip title="restore admin">
              <FaTrashRestoreAlt size={20} onClick={() => handleRestore(_id)} />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Service Trash</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Service Trash</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <Table
                class="table table-stripped table-hover datatable"
                pagination={{
                  total: service?.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                columns={column}
                loading={loader}
                dataSource={service}
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTrashList;
